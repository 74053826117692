table.status-list tr {
  /*border-bottom: 1px solid #ccc;*/
}
table.status-list td.check,
span.status-check {
  background-image: url(../images/symbol-checkmark-green-solid-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.open,
span.status-open {
  background-image: url(../images/symbol-play-solidfill-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.hold,
span.status-hold {
  background-image: url(../images/symbol-pause-outline-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.ready,
span.status-ready {
  background-image: url(../images/symbol-thumbs-up-green-outline-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.urgent {
  background-image: url(../images/symbol-alert-red-outline-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
/*table.status-list tr.active {
  color: #255b1e;
  background-color: #bebfb9;
}
table.status-list tr.hold {
  color: #040f37;
  background-color: #fffce5;
}*/
table.status-list td.new {
  background-image: url(../images/symbol-asterisk-solidfill-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
}
span.urgent {
  color: red;
  font-weight: bold;
}
