div.request-header .header-item {
  width: 32%;
  display: inline-block;
}
.uwf-details .details-container {
  //display: flex;
  //justify-content: flex-start;
  font-size: 14px;
}
//.uwf-details .details-container .detail-pane {
//  width: auto;
//  min-width: 200px;
//}
//.detail-pane {
//  width: 32%;
//  display: inline-block;
//  vertical-align: top;
//}
//.detail-pane:not(:last-child) {
//  padding-right: 15px;
//}
.detail-pane {
  padding: 0 1em 0 0;
  margin-bottom: 1em;
}
.uwf-details h3,
.detail-pane h3,
.uwf-details h4,
.detail-pane h4 {
  font-weight: bold;
  margin-bottom: 3px;
}
.uwf-details h3:not(:first-child),
.detail-pane h3:not(:first-child),
.uwf-details h4:not(:first-child),
.detail-pane h4:not(:first-child) {
  margin-top: 10px;
}


.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border-radius: 4px;
}
.well h2,
.well h3 {
  margin-top:0;
}
