.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
div.request-header .header-item {
  width: 32%;
  display: inline-block;
}
.uwf-details .details-container {
  font-size: 14px;
}
.detail-pane {
  padding: 0 1em 0 0;
  margin-bottom: 1em;
}
.uwf-details h3,
.detail-pane h3,
.uwf-details h4,
.detail-pane h4 {
  font-weight: bold;
  margin-bottom: 3px;
}
.uwf-details h3:not(:first-child),
.detail-pane h3:not(:first-child),
.uwf-details h4:not(:first-child),
.detail-pane h4:not(:first-child) {
  margin-top: 10px;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border-radius: 4px;
}
.well h2,
.well h3 {
  margin-top: 0;
}
table.status-list tr {
  /*border-bottom: 1px solid #ccc;*/
}
table.status-list td.check,
span.status-check {
  background-image: url(../images/symbol-checkmark-green-solid-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.open,
span.status-open {
  background-image: url(../images/symbol-play-solidfill-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.hold,
span.status-hold {
  background-image: url(../images/symbol-pause-outline-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.ready,
span.status-ready {
  background-image: url(../images/symbol-thumbs-up-green-outline-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
table.status-list td.urgent {
  background-image: url(../images/symbol-alert-red-outline-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
  padding-left: 35px;
}
/*table.status-list tr.active {
  color: #255b1e;
  background-color: #bebfb9;
}
table.status-list tr.hold {
  color: #040f37;
  background-color: #fffce5;
}*/
table.status-list td.new {
  background-image: url(../images/symbol-asterisk-solidfill-20x20.png);
  background-position: 8px center;
  background-repeat: no-repeat;
}
span.urgent {
  color: red;
  font-weight: bold;
}
/* Fix Krumo dpm() output indents */
div.krumo-wrapper ul.krumo-node ul {
  margin-left: 20px;
}
body.adminimal-skin-material div.messages ul.krumo-node ul {
  margin-left: 20px;
}
.field-label-inline .field-label {
  padding-right: 5px;
}
.field {
  margin-top: 5px;
}
.form-inline .form-markup-inline,
.form-inline .form-type-checkboxes,
.form-inline .form-type-item,
.form-inline .form-type-radios,
.form-inline .form-type-select,
.form-inline .form-type-textfield,
.form-inline .container-inline-date,
.form-inline .text-format-wrapper {
  display: inline-block;
  vertical-align: top;
}
@media only screen and (max-width: 480px) {
  .form-inline .form-markup-inline,
  .form-inline .form-type-checkboxes,
  .form-inline .form-type-item,
  .form-inline .form-type-radios,
  .form-inline .form-type-select,
  .form-inline .form-type-textfield,
  .form-inline .container-inline-date,
  .form-inline .text-format-wrapper {
    display: block;
  }
}
.form-inline-flex-wrapper .form-inline-flex {
  display: flex;
}
.form-inline-flex-wrapper .form-inline-flex.flex-wrap {
  flex-wrap: wrap;
}
.form-inline-flex-wrapper .form-inline-flex .form-markup-inline:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .form-type-checkboxes:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .form-type-item:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .form-type-radios:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .form-type-select:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .form-type-textfield:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .container-inline-date:not(:last-child),
.form-inline-flex-wrapper .form-inline-flex .text-format-wrapper:not(:last-child) {
  margin-right: 2em;
}
@media only screen and (max-width: 767px) {
  .form-inline-flex-wrapper .form-inline-flex {
    display: revert;
  }
  .form-inline-flex-wrapper .form-inline-flex .form-markup-inline:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .form-type-checkboxes:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .form-type-item:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .form-type-radios:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .form-type-select:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .form-type-textfield:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .container-inline-date:not(:last-child),
  .form-inline-flex-wrapper .form-inline-flex .text-format-wrapper:not(:last-child) {
    margin-right: unset;
  }
}
/*.form-inline .form-markup-label {*/
/*  vertical-align: top;*/
/*}*/
/*.form-inline .form-group--submit {*/
/*  vertical-align: bottom;*/
/*}*/
/*.form-inline .form-control {*/
/*  height: 35px;*/
/*}*/
/*.form-block-labels label.control-label {*/
/*  display: block;*/
/*}*/
/*.form-inline-flex {*/
/*  display: -webkit-box;*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  -ms-flex-wrap: wrap;*/
/*  flex-wrap: wrap;*/
/*  -webkit-box-pack: justify;*/
/*  -ms-flex-pack: justify;*/
/*  justify-content: space-between;*/
/*  -webkit-column-gap: 15px;*/
/*  -moz-column-gap: 15px;*/
/*  column-gap: 15px;*/
/*  margin-bottom: 15px;*/
/*}*/
/*@media (max-width: 767px) {*/
/*  .form-inline-flex {*/
/*    display: block;*/
/*  }*/
/*}*/
/*.form-inline-flex > .form-group,*/
/*.form-inline-flex > .field-group--inline {*/
/*  -webkit-box-flex: 1;*/
/*  -ms-flex: 1;*/
/*  flex: 1;*/
/*  margin-right: 0;*/
/*  margin-bottom: 0;*/
/*}*/
/*.form-inline-flex > .form-group.flex-less,*/
/*.form-inline-flex > .field-group--inline.flex-less {*/
/*  -webkit-box-flex: 0;*/
/*  -ms-flex: 0;*/
/*  flex: 0;*/
/*}*/
/*.form-inline-flex.flex-col-2 .form-group,*/
/*.form-inline-flex.flex-col-2 .field-group--inline {*/
/*  min-width: 40%;*/
/*}*/
/*.form-inline-flex.flex-col-3 .form-group,*/
/*.form-inline-flex.flex-col-3 .field-group--inline {*/
/*  min-width: 28%;*/
/*}*/
/*.form-inline-flex.form-inline-flex--default,*/
/*.field-group--inline.field-group--default {*/
/*  padding: 15px;*/
/*  background-color: #f5f5f5;*/
/*}*/
/*.form-inline .form-group:not(:last-child) {*/
/*  margin-right: 15px;*/
/*}*/
/*@media (max-width: 767px) {*/
/*  .form-inline .form-group:not(:last-child) {*/
/*    margin-right: 0;*/
/*  }*/
/*}*/
