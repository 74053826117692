
.form-inline {
  .form-markup-inline,
  .form-type-checkboxes,
  .form-type-item,
  .form-type-radios,
  .form-type-select,
  .form-type-textfield,
  .container-inline-date,
  .text-format-wrapper {
    display: inline-block;
    vertical-align: top;
  }
  @media only screen and (max-width: 480px) {
    //.form-inline {
    .form-markup-inline,
    .form-type-checkboxes,
    .form-type-item,
    .form-type-radios,
    .form-type-select,
    .form-type-textfield,
    .container-inline-date,
    .text-format-wrapper {
      display: block;
    }
  }
}

.form-inline-flex-wrapper {
  .form-inline-flex {
    display: flex;
    &.flex-wrap {
      flex-wrap: wrap;
    }
    .form-markup-inline,
    .form-type-checkboxes,
    .form-type-item,
    .form-type-radios,
    .form-type-select,
    .form-type-textfield,
    .container-inline-date,
    .text-format-wrapper {
      &:not(:last-child) {
        margin-right: 2em;
      }
    }

    @media only screen and (max-width: 767px) {
      display: revert;
      .form-markup-inline,
      .form-type-checkboxes,
      .form-type-item,
      .form-type-radios,
      .form-type-select,
      .form-type-textfield,
      .container-inline-date,
      .text-format-wrapper {
        &:not(:last-child) {
          margin-right: unset;
        }
      }
    }
  }
}
/*.form-inline .form-markup-label {*/
/*  vertical-align: top;*/
/*}*/
/*.form-inline .form-group--submit {*/
/*  vertical-align: bottom;*/
/*}*/
/*.form-inline .form-control {*/
/*  height: 35px;*/
/*}*/
/*.form-block-labels label.control-label {*/
/*  display: block;*/
/*}*/
/*.form-inline-flex {*/
/*  display: -webkit-box;*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  -ms-flex-wrap: wrap;*/
/*  flex-wrap: wrap;*/
/*  -webkit-box-pack: justify;*/
/*  -ms-flex-pack: justify;*/
/*  justify-content: space-between;*/
/*  -webkit-column-gap: 15px;*/
/*  -moz-column-gap: 15px;*/
/*  column-gap: 15px;*/
/*  margin-bottom: 15px;*/
/*}*/
/*@media (max-width: 767px) {*/
/*  .form-inline-flex {*/
/*    display: block;*/
/*  }*/
/*}*/
/*.form-inline-flex > .form-group,*/
/*.form-inline-flex > .field-group--inline {*/
/*  -webkit-box-flex: 1;*/
/*  -ms-flex: 1;*/
/*  flex: 1;*/
/*  margin-right: 0;*/
/*  margin-bottom: 0;*/
/*}*/
/*.form-inline-flex > .form-group.flex-less,*/
/*.form-inline-flex > .field-group--inline.flex-less {*/
/*  -webkit-box-flex: 0;*/
/*  -ms-flex: 0;*/
/*  flex: 0;*/
/*}*/
/*.form-inline-flex.flex-col-2 .form-group,*/
/*.form-inline-flex.flex-col-2 .field-group--inline {*/
/*  min-width: 40%;*/
/*}*/
/*.form-inline-flex.flex-col-3 .form-group,*/
/*.form-inline-flex.flex-col-3 .field-group--inline {*/
/*  min-width: 28%;*/
/*}*/
/*.form-inline-flex.form-inline-flex--default,*/
/*.field-group--inline.field-group--default {*/
/*  padding: 15px;*/
/*  background-color: #f5f5f5;*/
/*}*/
/*.form-inline .form-group:not(:last-child) {*/
/*  margin-right: 15px;*/
/*}*/
/*@media (max-width: 767px) {*/
/*  .form-inline .form-group:not(:last-child) {*/
/*    margin-right: 0;*/
/*  }*/
/*}*/
